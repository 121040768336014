<template>
  <div class="bg">
      <Tops></Tops>
      <div class="cont">
          <div class="ret" @click="home">返回 </div>
          <div class="infor">
              <div>
                  <div class="content">
                      <h1>数据管理平台</h1>
                      <!--<p class="margin"> 数据管理平台是对数据库内数据进行查询展示的地方，对数据进行管理。用户可查看各模块的数据，
                        支持按不同维度条件进行检索，按不同数据、时间类数据进行排序，支持数据的导入导出等功能，为用户展示具体的数据。</p>
                      <p>通过梳理业务流程，将相关数据按照业务环节进行分类和归集，制定业务采集数据标准；建立数据模型及对应的库表结构，
                        每个模型包含多张数据库表；将数据以星形模型进行组织，搭建数据仓库，为后续分析模型，预测模型和应用平台提供数据基础。
                        提供模型数据录入、查询统计、数据导出等功能。</p>-->
                      <p class="margin">有智慧的数据仓库</p>
                      <p>可根据业务所需，自行制定数据采集的分类和归集标准，进行数据检索、数据模型、数据展示、数据管理……操作简便，让大数据为工作生产节能增效，提升价值</p>
                      <button @click="login">立即登录</button>
                  </div>
              </div>
              <div>
                  <div class="content1">
                      <h1>数据分析平台</h1>
                      <!--<p class="margin">数据分析平台是基于数据管理平台的数据进行初步分析，将较为重点的指标以图表的形式进行展示，
                        使用户能够看到具体的分布情况、走势等。</p>
                      <p>数据分析平台基于数据管理平台的数据基础，对试点县生猪存栏、出栏、补栏等生产形势进行分析；对生猪流通来源地、目的地、
                        数量等流通形势进行分析；对生猪的供应量、市场消费量，及养殖、屠宰、市场等环节价格等供需形势进行分析；
                        对县域兽医配置及车辆配置等检疫监管情况进行分析；对养殖场粪污资源利用等生猪环保形势进行分析。</p>-->
                      <p class="margin">实用的数据价值挖掘工具</p>
                      <p>以数据管理平台内的数据为基础，围绕工作生产中的重点指标等，展开数据分析，形成可供参考的数据图表、走势和结论分析等，
                        让大数据为工作生产辅助决策，添砖加瓦</p>
                      <button @click="logins">立即登录</button>
                  </div>
              </div>
          </div>
      </div>
      <Footers></Footers>
  </div>
</template>

<script>
import Footers from './components/footers.vue'
import Tops from './components/tops.vue'
export default {
  components: { Tops, Footers },
  methods:{
      home(){
          this.$router.push('/home')
      },
       login(){
         if(window.location.href.indexOf("?CAS_VALIDATION")!=-1){
            location.href='http://dasp.net.cn:7780/breeding_vue/#/user/login?CAS_VALIDATION'
         }else{
           location.href='http://dasp.net.cn:7780/breeding_vue/#/user/login'
         }

      },
      logins(){
        if(window.location.href.indexOf("?CAS_VALIDATION")!=-1){
           location.href='http://dasp.net.cn:7780/analysis_vue/#/user/login?CAS_VALIDATION'
        }else{
          location.href='http://dasp.net.cn:7780/analysis_vue/#/user/login'
        }
          
      }
  },
  mounted () {

  }
}
</script>

<style scoped>
    .bg{
        width: 100%;
        height: 100%;
        margin: 0 auto;
        background: url(../../assets/img/agrbg.png) no-repeat;
        background-position: center center;
        background-size: cover;
    }
    .cont{
        height: 800px;
    }
    .infor{
        height: 100%;
        width: 1200px;
        margin: 0 auto;
    }
    .infor>div{
        height: 50%;
        position: relative;
    }
    .infor>div:nth-child(1){
        border-bottom: 1px solid #c9d0e3;
    }
    .infor p{
        /*text-indent: 2em;*/
        font-size: 16px;
        line-height: 30px;
        color: #515151;
    }
    .content{
        width: 410px;
        color: #666;
        position: absolute;
        top: 40px;
        left: 100px;
    }
    .content h1{
        color: #0e5ce0;
        font-size: 24px;
        font-weight: bold;
    }
    .margin{
        margin: 5px 0;
        font-weight: bold;
    }
    .content button{
        background-color: transparent;
        color: #0e5ce0;
        width: 120px;
        border: 1px solid #0e5ce0;
        border-radius: 5px;
        margin-left: 280px;
        cursor: pointer;
        margin-top: 30px;
        padding: 7px 20px;
    }
    .content1{
        width: 410px;
        color: #666;
        position: absolute;
        top: 70px;
        right: 100px;
    }
    .content1 h1{
        color: #0198ac;
        font-size: 24px;
        font-weight: bold;
    }
    .content1 button{
        background-color: transparent;
        color: #0198ac;
        width: 120px;
        border: 1px solid #0198ac;
        border-radius: 5px;
        margin-left: 300px;
        cursor: pointer;
        margin-top: 30px;
        padding: 7px 20px;
    }
    .ret{
        font-weight: bold;
        font-size: 18px;
        margin-left: 40px;
        margin-top: 20px;
        cursor: pointer;
        padding-left: 22px;
        background-size: 16px auto;
        background-image: url("~@/assets/img/icon_back_arr.png");
        background-repeat: no-repeat;
        background-position: left center;
    }
  @media screen and (max-width: 1920px) {
    .content{
      left: 90px;
    }
    .content1{
      right: 90px;
    }
  }
    @media screen and (max-width: 1600px) {
      .content{
        left: 90px;
      }
      .content1{
        right: 90px;
      }
    }
</style>
