<template>
  <div>
    <div class="footer">
      <div class="footer-top">
        <h1><span>友情链接</span></h1>
        <ul class="flex">
          <li><a href="https://www.wens.com.cn">温氏股份</a></li>
          <li><a href="https://www.junciot.com">筠诚物联网</a></li>
          <li><a href="http://www.xinm123.com">新牧网</a></li>
          <li><a href="http://www.ixumu.com">爱畜牧</a></li>
          <li><a href="http://www.gdswine.com">养猪信息网</a></li>
          <li><a href="http://www.xmdj123.com">畜牧大集网</a></li>
          <li><a href="https://www.zhue.com.cn">猪易网</a></li>
          <li><a href="http://www.gdfeed.org.cn">广东饲料信息网</a></li>
          <li class="bordernone"><a href="https://www.wepig.cn">微猪科技</a></li>
        </ul>
      </div>
    </div>
    <div class="copyright">
      <h2><span class="mr50">主管单位：新兴县农业农村局</span><span>网站运营：云浮市物联网研究院有限公司</span></h2>
      <h3><img src="@/assets/img/foot-img_03.png">粤ICP备21111111</h3>
    </div>
  </div>
</template>

<script>
  export default {
  }
</script>

<style scoped>
  .footer{
    margin: 38.5px auto 0;
    position: relative;
    width: 90%;
    max-width: 1300px;
  }
  .footer .footer-top{
    width: 100%;
    margin: 0 auto 35px;
  }
  .footer .footer-top h1{
    text-align: left;
    color: #000;
    font-size: 18px;
  }
  .footer .footer-top ul{
    overflow: hidden;
    margin: 16px auto 0;
    display: flex;
    justify-content: space-between;
  }
  .footer li{
    font-size: 16px;
    color: #000;
    text-align: center;
    border-right: 2px solid #878888;
    height: 22px;
    padding: 0 2% 0 0;
  }
  .footer .bordernone{
    border-right: none;
  }
  /* .footer li+li::before{
    content: "|";
    margin: 35px;
    color: #878888;
  } */
  .copyright{
    width: 100%;
    background-color: #51d5c3;
    bottom: 0;
    color: #fff;
    text-align: center;
    padding: 10px 0 10px;
  }
  .footer a{
    color: #878888;
    font-size: 17px;
  }
  .footer a:hover {
    color:#51d5c3;
  }
  .footer .footer-top h1 span {
    padding-bottom: 5px;
    border-bottom: 1px solid #fff;
    display: inline-block;
    border-top: 6px solid #51D5c3;
    font-weight: bolder;
    padding-top: 8px;
    padding-right: 10px;
  }
  .copyright h2, .copyright h3{
    font-size: 14px  !important;
  }
  .copyright h2{
    line-height: 40px;
  }
  .copyright img{
    width: 17px;
    height: 20px;
  }
  .copyright h3{
    position: relative;
  }
  .copyright h3 img{
    margin-right: 16px;
    vertical-align: middle;
  }
  .mr50{margin-right: 50px;}
</style>
